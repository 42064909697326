import React from "react";

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const VoiceBiometrics: React.FC<Props> = ({color='#fff',height=57,width=57}) =>{
    return (
        <svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33268 23.9437C2.33268 23.9437 1.66602 23.277 1.66602 22.277V17.277C1.66602 16.277 2.33268 15.6104 3.33268 15.6104C4.33268 15.6104 4.99935 16.277 4.99935 17.277V22.277C4.99935 23.277 4.33268 23.9437 3.33268 23.9437Z" fill={color}/>
            <path d="M10.0007 30.61C9.00065 30.61 8.33398 29.9434 8.33398 28.9434V10.61C8.33398 9.61003 9.00065 8.94336 10.0007 8.94336C11.0007 8.94336 11.6673 9.61003 11.6673 10.61V28.9434C11.6673 29.9434 11.0007 30.61 10.0007 30.61Z" fill={color}/>
            <path d="M16.6667 37.2767C15.6667 37.2767 15 36.61 15 35.61V5.61003C15 4.61003 15.6667 3.94336 16.6667 3.94336C17.6667 3.94336 18.3333 4.61003 18.3333 5.61003V35.61C18.3333 36.61 17.6667 37.2767 16.6667 37.2767Z" fill={color}/>
            <path d="M23.3327 27.2773C22.3327 27.2773 21.666 26.6107 21.666 25.6107V13.944C21.666 12.944 22.3327 12.2773 23.3327 12.2773C24.3327 12.2773 24.9993 12.944 24.9993 13.944V25.6107C24.9993 26.6107 24.3327 27.2773 23.3327 27.2773Z" fill={color}/>
            <path d="M30.0007 32.2773C29.0007 32.2773 28.334 31.6107 28.334 30.6107V8.94401C28.334 7.94401 29.0007 7.27734 30.0007 7.27734C31.0007 7.27734 31.6673 7.94401 31.6673 8.94401V30.6107C31.6673 31.6107 31.0007 32.2773 30.0007 32.2773Z" fill={color}/>
            <path d="M36.6667 23.9437C35.6667 23.9437 35 23.277 35 22.277V17.277C35 16.277 35.6667 15.6104 36.6667 15.6104C37.6667 15.6104 38.3333 16.277 38.3333 17.277V22.277C38.3333 23.277 37.6667 23.9437 36.6667 23.9437Z" fill={color}/>
        </svg>
    )
}

export default VoiceBiometrics;