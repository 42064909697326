import React from 'react';
import './OptionModalHome.css'
import {Text} from "@chakra-ui/react";

import {Link} from "react-router-dom";
import {CardCustom} from "@canaia/ui-kit";


interface Props {
    image: JSX.Element,
    title: string;
    description: string;
    link: string;
    status: string;
}

const OptionModalHome: React.FC<Props> = ({image,title,description,link, status}) => {

    const CardProps = {
        children: (
            <Link className={`option-modal-home ${status}`} to={link}>
                {image}
                <Text className="option-modal-title" fontFamily={'Sansation-Bold'} fontSize={'1.375rem'} textTransform='capitalize' color={'#fa6448'} maxWidth={'165px'} width={'100%'} margin={'0.875rem auto 0'} textAlign={'center'}>
                    {title}
                </Text>
                <Text className="option-modal-description" fontFamily={'Sansation-Regular'} fontSize={'1rem'} color={'#222'} maxWidth={'158px'} width={'100%'} margin={'1.5rem auto'} textAlign={'center'}>
                    {description}
                </Text>
            </Link>
        )
    }
    return(
        <>
            <CardCustom backgroundColor='#fff' maxWidth='206px' padding={'0'} borderRadius='1rem' {...CardProps}/>
        </>
    );
}

export default OptionModalHome;