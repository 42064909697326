import {useEffect, useState, useRef, useCallback} from "react";
import {TypingIndicator} from "@chatscope/chat-ui-kit-react";
import {
    useToast,
    Box, VStack
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import { useGetTokenOrganization } from "../../../../../hooks/useGetTokenOrganization";
import {ChatInput, ChatUser, ChatAssistant} from "@canaia/ui-kit";

interface ImageInteractionChatProps {
    image: string | null;
}

interface ChatMessage {
    content: string;
    direction: "incoming" | "outgoing";
    sender: "bot" | "user";
}

function processResponse(rawText: string, accumulatedMessage: string): string {

    const lines = rawText.split("\n");
    const dataLines = lines.filter(
        (line) =>
            line.startsWith("data: ") &&
            !line.includes("event: stats") &&
            !line.includes("time_to_first_token") &&
            !line.includes("time_to_last_token")
    );
    const newContent = dataLines
        .map((line) => line.replace("data: ", ""))
        .join("");

    if (accumulatedMessage.endsWith("") || accumulatedMessage === "") {
        return accumulatedMessage + newContent;
    } else {
        return accumulatedMessage + " " + newContent;
    }
}

export const ImageInteractionChat = ({image}: ImageInteractionChatProps) => {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [, setUserMessage] = useState<string>("");
    const [accumulatedMessage, setAccumulatedMessage] = useState<string>("");
    const { t } = useTranslation();

    const initialFetchRef = useRef(false);
    const toast = useToast();
    const { fetchUpdatedToken } = useGetTokenOrganization();

    const handleStream = useCallback(async (userMessage: string) => {
        setIsLoading(true);

        try {
            const token = await fetchUpdatedToken();
            if (!token) throw new Error(t("Failed to obtain access token."));
            if (!image) throw new Error(t("Image is empty"));

            const body = JSON.stringify({
                model: "openai:gpt-4o",
                system_prompt: "string",
                temperature: 0.5,
                messages: [
                    {
                        role: "user",
                        content: userMessage,
                        image: image.replace(/^data:image\/[a-z]+;base64,/, ""),
                    },
                ],
            });

            const response = await fetch(`${import.meta.env.VITE_API_ENDPOINT}/api/experiments/image-understanding`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body,
            });

            const reader = response.body?.getReader();
            const decoder = new TextDecoder();

            if (!reader) throw new Error("No stream available.");

            let accumulatedChunk = "";
            let done = false;

            while (!done) {
                const result = await reader.read();
                done = result.done;
                if (done) break;
                const chunk = decoder.decode(result.value, {stream: true});
                accumulatedChunk = processResponse(chunk, accumulatedChunk);
                setAccumulatedMessage(accumulatedChunk);
            }

            setMessages((prevMessages) => [
                ...prevMessages,
                {content: accumulatedChunk, direction: "incoming", sender: "bot"},
            ]);
            setAccumulatedMessage("");
        } catch (error) {
            console.error("Error while streaming:", error);
            toast({
                title: t("Connection Error"),
                description: t("Failed to process your request. Please check your connection and try again."),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image, toast,t]);

    const handleSendMessage = (userMessage:string) => {
        if (userMessage.trim()) {
            setMessages((prevMessages) => [
                ...prevMessages,
                {content: userMessage, direction: "outgoing", sender: "user"},
            ]);
            setAccumulatedMessage("");
            handleStream(userMessage).then();
            setUserMessage("");
        }
    };

    useEffect(() => {
        if (!initialFetchRef.current) {
            initialFetchRef.current = true;

            handleStream(t("What's in this image?"))
                .catch(() => {
                    toast({
                        title: t("Initial Request Failed"),
                        description: t("Unable to analyze the image. Please try again."),
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                });
        }
    }, [handleStream, toast,t]);

    return (
        <>

            <VStack className="custom-scrollbar" overflowY="auto" backgroundColor='#666' border='1px solid #c1c1c1' borderRadius='2xl' marginTop='2.5rem' maxWidth='756px' width='100%'>

                <Box padding='0 3.625rem 2.6875rem' width='100%'>
                    <ChatUser
                        userImage="https://img.freepik.com/fotos-premium/icono-perfil-fondo-blanco_941097-161416.jpg?w=826"
                        type={'image'}
                        src={image}
                        alt={'Imagen subida por usuario'}
                    />

                    {messages.map((msg, index) => (
                        <div key={index}>
                            {msg.sender === 'user' ? (
                                <ChatUser
                                    message={msg.content}
                                    userImage="https://img.freepik.com/fotos-premium/icono-perfil-fondo-blanco_941097-161416.jpg?w=826"  // Imagen del usuario (puedes pasar una imagen dinámica si lo prefieres)
                                    timestamp=""
                                />
                            ) : (
                                <ChatAssistant
                                    key={index}
                                    message={msg.content}
                                    colorTextMessage='#fff'
                                    color="play.600"
                                    thumbUp={false}
                                    thumbDown={false}
                                    withFeedback={true}
                                    isTyping={false}
                                    onThumbUp={() => { console.log("Thumb Up"); }}
                                    onThumbDown={() => { console.log("Thumb Down"); }}
                                    colorCopyIcon={'#fff'}
                                    colorThumbUp={'#fff'}
                                    colorThumbDown={'#fff'}
                                />
                            )}
                        </div>
                    ))}

                    {accumulatedMessage && (
                        <ChatAssistant
                            message={accumulatedMessage}
                            colorTextMessage='#fff'
                            color="play.600"
                            thumbUp={false}
                            thumbDown={false}
                            withFeedback={true}
                            isTyping={true}
                            onThumbUp={() => { console.log("Thumb Up"); }}
                            onThumbDown={() => { console.log("Thumb Down"); }}
                            colorCopyIcon={'#fff'}
                            colorThumbUp={'#fff'}
                            colorThumbDown={'#fff'}
                        />
                    )}
                    {!isLoading && (
                        <Box margin='0 auto' padding='6.5625rem 0 0' color={'#666'} maxWidth='640px' width="100%">
                            <ChatInput onSend={handleSendMessage} placeholder="Escribe tu mensaje..." />
                        </Box>
                    )}
                    {isLoading && <TypingIndicator content={t("The Assistant is typing...")} />}
                </Box>
            </VStack>
        </>

    );
};