import React, { useEffect, useRef, useState } from "react";
import "./stylesChatBox.css";
import {
    Box,
    Flex,
    Text,
    VStack,
    HStack,
} from "@chakra-ui/react";
import { useStreamingResponse } from "../../../hooks/streamingChat/useStreamingResponse";
import { ChatBoxProps, contentMessage } from "./interfaces";
import { useTranslation } from "react-i18next";
import {ChatInput, ChatUser, ChatAssistant} from "@canaia/ui-kit";

const ChatBox: React.FC<ChatBoxProps> = ({
    showToastError,
    sendUserMessage,
}) => {
    const { t } = useTranslation();
    const [conversation, setConversation] = useState<contentMessage[]>([]);
    const [currentTypingMessage, setCurrentTypingMessage] = useState<string>("");
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const [shouldProcessAfterConversationUpdate, setShouldProcessAfterConversationUpdate] = useState(false);

    const { startStreaming, partialResponse } = useStreamingResponse({
        url: `${import.meta.env.VITE_API_ENDPOINT}/api/experiments/large-language-model`,
        body: {
            model: sendUserMessage.model,
            system_prompt: sendUserMessage.system_prompt,
            temperature: 0.5,
            messages: conversation,
        },
        onComplete: (finalMessage) => {
            if(finalMessage) {
                setConversation((prev) => [
                    ...prev,
                    { role: "assistant", content: finalMessage },
                ]);
            } else {
                setConversation((prev) => [
                    ...prev,
                    { role: "assistant", content: "Error: No se pudo obtener la respuesta." },
                ]);
            }

            setCurrentTypingMessage("");
        },
        showToastError,
    });

    useEffect(() => {
        if (partialResponse) {
            setCurrentTypingMessage((prev) => prev + partialResponse);
        }
    }, [partialResponse]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [conversation, currentTypingMessage]);

    const handleSendMessage = (message: string) => {
        if (!message.trim()) return;
        const userMessage = { role: "user", content: message };

        setConversation((prev) => [...prev, userMessage]);
        setShouldProcessAfterConversationUpdate(true);

    };

    useEffect(() => {
        if (shouldProcessAfterConversationUpdate) {
            startStreaming();
            setShouldProcessAfterConversationUpdate(false);
        }
    }, [shouldProcessAfterConversationUpdate, startStreaming]);

    const chatInputProps = {
        placeholder: 'Escribe un mensaje',
        onSend: handleSendMessage
    }

    return (
        <Box
            maxW="771px"
            w="100%"
            bg="#666"
            borderRadius="md"
            p={4}
            boxShadow="md"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            maxHeight="680px"
            border={'1px solid #fff'}
        >
            {conversation.length === 0 && (
                <Flex
                    w="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    maxHeight="680px"
                >
                    <Text fontWeight="normal" fontSize="20px" mb="33px" textAlign="center">
                        {t("How can I help you?")}
                    </Text>
                </Flex>
            )}
            <VStack
                spacing={4}
                align="start"
                overflowY="auto"
                flex="1"
                mb={4}
                paddingRight="4px"
                width="100%"
                ref={chatContainerRef}
                className="custom-scrollbar"
            >
                {conversation.map((msg, index) => (
                    msg.content && (
                        <HStack
                            key={index}
                            alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}
                            width={"100%"}
                        >
                            {msg.role === "user" ? (
                                <Box
                                    color="white"
                                    px={3}
                                    py={2}
                                    borderRadius="lg"
                                    textAlign="left"
                                    width='100%'
                                >
                                    <ChatUser
                                        message={msg.content}
                                        userImage="https://img.freepik.com/fotos-premium/icono-perfil-fondo-blanco_941097-161416.jpg?w=826"  // Imagen del usuario (puedes pasar una imagen dinámica si lo prefieres)
                                        timestamp=""
                                    />
                                </Box>
                            ):(
                                // For wizard messages
                                <Box
                                    color="white"
                                    px={3}
                                    py={2}
                                    borderRadius="lg"
                                    textAlign="right"
                                    maxWidth='100%'
                                >

                                    <ChatAssistant
                                        message={msg.content}
                                        colorTextMessage={'#fff'}
                                        color="play.600"
                                        thumbUp={false}
                                        thumbDown={false}
                                        withFeedback={true}
                                        isTyping={false}
                                        onThumbUp={() => { console.log("Thumb Up"); }}
                                        onThumbDown={() => { console.log("Thumb Down"); }}
                                        isError={msg.content.startsWith("Error:")}
                                        colorCopyIcon={'#fff'}
                                        colorThumbUp={'#fff'}
                                        colorThumbDown={'#fff'}
                                    />
                                </Box>
                            )}
                        </HStack>
                    )
                ))}
                {currentTypingMessage && (
                    <HStack alignSelf="flex-start" maxWidth="80%">
                        <Box
                            px={3}
                            py={2}
                            borderRadius="lg"
                            textAlign="left"
                        >
                            <Text fontSize="sm" whiteSpace="pre-wrap">

                                <ChatAssistant
                                    message={currentTypingMessage}
                                    colorTextMessage={'#fff'}
                                    color="play.600"
                                    thumbUp={false}
                                    thumbDown={false}
                                    withFeedback={true}
                                    isTyping={false}
                                    onThumbUp={() => { console.log("Thumb Up"); }}
                                    onThumbDown={() => { console.log("Thumb Down"); }}
                                    isError={currentTypingMessage.startsWith("Error:")}
                                    colorCopyIcon={'#fff'}
                                    colorThumbUp={'#fff'}
                                    colorThumbDown={'#fff'}
                                />
                            </Text>
                        </Box>
                    </HStack>
                )}
            </VStack>
            <Flex alignItems="center" mt={2} color={'#666'}>
                <ChatInput {...chatInputProps} />
            </Flex>
        </Box>
    );
};

export default ChatBox;
