import { Box, Flex, Stack, Text} from "@chakra-ui/react";
import MainTitle from "../../../../ui/Components/MainTitle";
import Subtitle from "../../../../ui/Components/SubtitleSeccion";
import { memo, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import SentimentResultsTable from "../../../../ui/Components/SentimentalResultTable";
import {providersListValue} from './mocks';
import { sentimentalAnalysisApi } from "../../../../api/brain/sentimentalAnalysisApi";
import { FormValues, resultsSentimental } from './interfaces'
import useToastError from "../../../../hooks/useToastError";
import { adjustDataModelsByLanguage } from "./utils";
import { useTranslation } from "react-i18next";
import { useGetTokenOrganization } from "../../../../hooks/useGetTokenOrganization";
import {SentimentalAnalysis} from "../../../../icons";
import {Input, Button, CustomCheckBox} from '@canaia/ui-kit';

const SentimentAnalysis = memo(() => {
    const { t } = useTranslation();
    const { handleSubmit, control, formState: { isSubmitting } } = useForm<FormValues>();
    const [ showResult, setShowResults ] = useState(false)
    const [results, setResults] = useState<resultsSentimental[]>([{name: "", text: "", time: 0}]);
    const showToastError = useToastError();

    const selectedModel = useWatch({ control, name: "model", defaultValue: [] });
    const enteredText = useWatch({ control, name: "text", defaultValue: "" });

    const isFormValid = !!selectedModel.length && enteredText.trim();
    const { fetchUpdatedToken } = useGetTokenOrganization();

    // Status to know if the form has been sent
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onSubmit = async (data: FormValues) => {
        const result = adjustDataModelsByLanguage(data)
        const token = await fetchUpdatedToken()

        if (typeof token !== 'string' || !token) {
            console.log(token)
            throw new Error(t("Failed to obtain access token."));
        }
        try {
            const apiResults = await sentimentalAnalysisApi(result, token);
            setResults(apiResults);
            setShowResults(true);
            setHasSubmitted(true); // Mark as sent when the form is submitted
        } catch (error) {
            setShowResults(false)
            showToastError(t('There was an error in the request.'))
        }
    };

    const CheckboxProps = {
        items:providersListValue,
        selectedOptions:[],
        isFileUpload: false,
        directionOptions: 'row',
        borderColor: '#fff',
        title:'Seleccionar proveedor',
        fontFamilyTitle: 'Sansation-Bold',
        fontSizeTitle: '1rem',
        colorTitle:'#fff',
        marginTitle: '0 0 .75rem',
        subtitle: 'Selecciona uno o varios de los siguientes proveedores para probar sus capacidades. A continuación, escribe el texto que deseas analizar.',
        fontFamilySubtitle: 'Lato',
        fontSizeSubtitle: '.75rem',
        colorSubtitle: '#fff',
    }
    const TextAreaProps = {
        colorLabelText:'white.100',
        fontFamilyText:'Sansation-Bold',
        fontSizeText: '1rem',
        isRequired: false,
        info: false,
        infoText: '',
        label : '',
        placeholder: 'Escribe el texto a analizar...',
        textArea: true,
        isReadOnly: false,
        isDisabled: false,
        minHeight: '120px'
    }
    const ButtonProps = {
        text: hasSubmitted ? 'Analizar de nuevo' : 'Analizar',
        type: 'subtle',
        color: 'white',
        bgColor: isFormValid ? 'play.600' : '#c1c1c1',
        onClick: handleSubmit(onSubmit),
        loading: isSubmitting,
        disabled: !isFormValid || isSubmitting,
    }

    return (
        <Flex flexDir="column">
            <Box
                maxWidth="771px"
                height="100vh"
                w="771px"
                mx="auto"
                padding="2.6875rem 0"
                borderRadius="md"
                color="white"
            >
           
                <Stack alignItems="flex-start">
                    <Flex mb=".25rem" alignItems="center">
                        <Flex mr="1.5rem">
                            <MainTitle text={t("Brain")} />
                        </Flex>
                        <Flex alignItems="center"> 
                            <Subtitle text={t("Sentiment Analysis")} icon={<SentimentalAnalysis width={24} height={24} color="#FFF" />} />
                        </Flex>
                    </Flex>
                    <Text fontWeight="bold" fontSize="20px" mb="33px" textAlign="initial">
                        {t("Identify the sentiment of the speakers on a conversation or message.")}
                    </Text>
                    <form 
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ width: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #c1c1c1', padding: '1.1875rem 1rem', borderRadius: '1rem', backgroundColor: '#666666'}}
                    >
                        <Controller
                            name="model"
                            control={control}
                            defaultValue={[]} 
                            render={({ field }) => (
                                <>
                                    <CustomCheckBox {...CheckboxProps} selectedOptions={field.value} onCheckBoxChange={field.onChange} value={field.value}/>
                                </>

                            )}
                        />
                        <Controller
                            name="text"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <>
                                    <Input {...TextAreaProps} onChange={field.onChange} value={field.value}/>
                                </>
                            )}
                        />

                        <Flex maxWidth='max-content' fontSize='1.125rem' margin='1.5rem auto 2.125rem'>
                            <Button {...ButtonProps}/>
                        </Flex>
                        {showResult &&  <SentimentResultsTable results={results} />}
                    </form>
                </Stack>
            </Box>
        </Flex>
    );
});

export default SentimentAnalysis;
