import React from "react";
import { Box, Flex, Text, VStack, Divider } from "@chakra-ui/react";
import IconTable from '../../../icons/tableIcons';
import {getSentimentColor} from './utils';
import {SentimentResultsTableProps} from './interfaces';
import { useTranslation } from "react-i18next";

const SentimentResultsTable: React.FC<SentimentResultsTableProps> = ({ results }) => {
    const { t } = useTranslation();
    return (
        <Box width="100%" maxW="741px" padding={'1.5rem'} borderRadius="1rem" backgroundColor={'#fff'}>
            <Text fontFamily={'Sansation-Bold'} fontWeight="bold" fontSize="lg" mb={4} textAlign={'left'} color={'#222222'}>
                {t("Result")}
                <Divider />
            </Text>
            <VStack spacing={4} align="stretch">
                {results.map((result, index) => (
                    <Box key={index} fontFamily={'Lato-Regular'} fontSize={'1rem'} color={'#222222'}>
                        <Flex align="center" justify="space-between" py={2}>
                            <Flex align="center" width="40%">
                                <Text mr={2} width="80px" textAlign={'left'}>
                                    {result.name}
                                </Text>
                             
                            </Flex>
                            <Flex align="center" width="60%">
                                <IconTable color={getSentimentColor(result.text)} />
                                <Text ml={2} textTransform="capitalize">
                                    {result.text.toLowerCase()}
                                </Text>
                            </Flex>
                            { result.time 
                                && <Text  width="50px" textAlign="right">
                                    {(result.time/1000).toFixed(2)} s
                                </Text> }
                      
                        </Flex>
                        {index < results.length - 1 && <Divider />}
                    </Box>
                ))}
            </VStack>
        </Box>
    );
};

export default SentimentResultsTable;
