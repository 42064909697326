import { useState } from "react";
import {Box, Flex, Stack, Text, Image, useToast} from "@chakra-ui/react";
import MainTitle from "../../../../ui/Components/MainTitle";
import Subtitle from "../../../../ui/Components/SubtitleSeccion";
import { Controller, useForm, useWatch } from "react-hook-form";
import {FormValues, imageProviders} from "./interfaces.tsx";
import base64ToBlob from "../../../../utils/base64ToBlob.tsx";
import { useTranslation } from "react-i18next";
import { useGetTokenOrganization } from "../../../../hooks/useGetTokenOrganization.tsx";
import AIGeneration from "../../../../icons/modalOptions/ai-generation.tsx";
import {Input, Select, Button, Spinner} from "@canaia/ui-kit";

export const ImageGeneration = () => {
    const { control, handleSubmit, formState: { isSubmitting }, reset} = useForm<FormValues>();
    const { t } = useTranslation();
    const toast = useToast();
    const { fetchUpdatedToken } = useGetTokenOrganization();
    
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [imageBlob, setImageBlob] = useState<Blob | null>(null);

    const selectedProviders = useWatch({
        control,
        name: "model",
        defaultValue: ""
    });

    const systemPrompt = useWatch({
        control, name: "system_prompt", defaultValue: ""
    });

    const onSubmit = async (data: FormValues) => {

        try {
            const token = await fetchUpdatedToken();

            if (!data.model || !data.system_prompt) throw new Error(t("Data is empty"));
            if (!token) throw new Error(t("Failed to obtain access token."));

            const body = JSON.stringify(
                {
                    "model": data.model,
                    "prompt": data.system_prompt
                }
            );

            const response = await fetch(`${import.meta.env.VITE_API_ENDPOINT}/api/experiments/image-generation`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body,
            });

            if (!response.ok) {
                toast({
                    title: "Error",
                    description: t("There was an error please try again"),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                throw new Error(t('Failed to generate image.'));
            }

            const responseBody = await response.json();

            if (responseBody && responseBody.image) {
                const base64Image = responseBody.image;
                const mimeType = "image/png";
                const blob = base64ToBlob(base64Image, mimeType);
                const url = URL.createObjectURL(blob);
                setImageUrl(url);
                setImageBlob(blob);
                toast({
                    title: t("Success"),
                    description: t("The image was created successfully"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Error",
                    description: t("There was an error please try again"),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                throw new Error(t("Image not found in response"));
            }
        } catch (error) {
            console.error(t("Error generating image:"), error);
            toast({
                title: "Error",
                description: t("An error occurred while generating the image."),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const downloadImage = () => {
        if (imageBlob) {
            const link = document.createElement("a");
            link.href = imageUrl!;
            link.download = "generated-image.png";
            link.click();
        }
    };

    const isButtonDisabled = !selectedProviders || systemPrompt.trim() === "";

    const handleReset = () =>{
        setImageUrl(null);
        reset({
            model: "",
            system_prompt: "",
        });
    }

    const SelectProps = {
        label: t('Proveedor IA'),
        fontFamilyLabel:'Sansation-Bold',
        fontSizeLabel: '1rem',
        options: imageProviders,
        placeholder: 'Choose an option',
        color: 'black.100',
        variant: 'filled',
        info: false,
        bgColorTooltip: 'rgba(254, 226, 220, 1)',
        borderTooltip:'1px solid rgba(249, 57, 22, 1)',
        borderRadiusTooltip: '.25rem',
        textAlignTooltip: 'justify',
        colorTextTooltip: '#222222',
        hasArrowTooltip: false,
        disableSelect: false,
        isRequired: false,
        textSubtitle: t('Selecciona uno o varios de los siguientes proveedores para probar sus capacidades.'),
        colorTextSubtitle: '#fff'
    }
    const TextAreaProps = {
        colorLabelText:'white.100',
        fontFamilyText:'Sansation-Bold',
        fontSizeText: '1rem',
        isRequired: false,
        info: false,
        label : t('Prompt'),
        placeholder: t('Escribe tus instrucciones para generar la imagen...'),
        textArea: true,
        isReadOnly: false,
        isDisabled: false,
        minHeight: '92px',
        textSubtitle: t('Describe la imagen a generar. Cuanto mas la detalles, mas precisa será.'),
        colorTextSubtitle: '#fff'
    }
    const ButtonProps = {
        text: t('Generar imagen'),
        type: 'subtle',
        color: 'white',
        bgColor: !isButtonDisabled ? 'play.600' : '#c1c1c1',
        onClick: handleSubmit(onSubmit),
        loading: isSubmitting,
        disabled: isButtonDisabled,
    }
    const SpinnerProps = {
        color: "play",
    }
    const ButtonDownloadProps = {
        text: t('Descargar'),
        type: 'subtle',
        color: 'white',
        bgColor: 'play.600',
        onClick: downloadImage
    }
    const ButtonBackProps = {
        text: t('Generar otra imagen'),
        type: 'subtle',
        color: 'white',
        bgColor: 'play.600',
        onClick: handleReset
    }

    return (
        <Flex flexDir="column">
            <Box
                maxWidth="790px"
                minHeight="100vh"
                w="790px"
                mx="auto"
                py="50px"
                px="15px"
                borderRadius="md"
                position="relative"
                color="white"
            >
                <Stack alignItems="flex-start" height={"90%"}>
                    <Flex alignItems="center" width={'100%'}>
                        <Flex mr="1.5rem">
                            <MainTitle text={t("Eyes")} />
                        </Flex>
                        <Flex alignItems="center">
                            <Subtitle text={t("Image generation")} icon={<AIGeneration width={20} height={20} color="#FFF" />} />
                        </Flex>

                        {imageUrl &&(
                            <Box marginLeft='auto'>
                                <Button {...ButtonBackProps}/>
                            </Box>
                        )}
                    </Flex>

                    <Text fontFamily={'Lato-Regular'} fontSize="1.1875rem" marginBottom="1.25rem">
                        {t("Describe something and let the Generative AI generate the image.")}
                    </Text>

                    {!imageUrl && (
                        <form style={{ width: '100%', display: 'flex', flexDirection: 'column',border:'1px solid #c1c1c1', borderRadius: '1rem', padding: '1.1875rem 1.1875rem ', backgroundColor: '#666'}}>
                            <Controller
                                name="model"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <Select {...SelectProps} onChange={field.onChange}/>
                                )}
                            />

                            <Controller
                                name="system_prompt"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <Box margin='1.5rem 0 0 0'>
                                        <Input {...TextAreaProps} onChange={field.onChange} value={field.value}/>
                                    </Box>
                                )}
                            />

                            <Flex maxWidth='max-content' fontSize='1.125rem' margin='1.56rem auto 2.4375rem' fontFamily={'Sansation-Bold'}>
                                <Button {...ButtonProps}/>
                            </Flex>
                        </form>
                    )}

                    {isSubmitting && (
                        <Flex justifyContent="center" width={"100%"} mt="20px">
                            <Spinner {...SpinnerProps}/>
                        </Flex>
                    )}

                    {imageUrl && (
                        <Box textAlign="center" border='1px solid #c1c1c1' borderRadius='1rem' padding='1.1875rem' backgroundColor='#666' width='100%' minHeight='27.125rem'>
                            <Image src={imageUrl} alt="Generated AI" maxWidth="18.625rem" height={"100%"}  borderRadius="md" margin='1rem auto 0' />
                            <Flex maxWidth='max-content' fontSize='1.125rem' margin='1.56rem auto 1rem' fontFamily={'Sansation-Bold'}>
                                <Button {...ButtonDownloadProps}/>
                            </Flex>
                        </Box>
                    )}
                </Stack>
            </Box>
        </Flex>
    );
};
