import {Box, Flex, Stack, Text, useToast} from "@chakra-ui/react";
import MainTitle from "../../../../ui/Components/MainTitle";
import Subtitle from "../../../../ui/Components/SubtitleSeccion";
import {useState} from "react";
import {ImageInteractionChat} from "./ImageInteractionChat";
import { useTranslation } from "react-i18next";
import AInteraction from "../../../../icons/modalOptions/ai-interaction.tsx";
import {Button, DragAndDrop, Spinner} from "@canaia/ui-kit";

export const ImageInteraction = () => {
    const [image, setImage] = useState<string | null>(null);
    const { t } = useTranslation();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();
    // 200MB en bytes
    const maxSize = 200 * 1024 * 1024;

    const handleReset = () => {
        setImage(null);
        setImageLoaded(false)
    };

    const handleFilesDropped = (files: File[]) => {
        const file = files[0];
        if(file) {
            if (file.size > maxSize) {
                toast({
                    title: "Error",
                    description: t("The file exceeds the maximum size of 200MB."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }

            setLoading(true);

            setTimeout(()=>{
                const reader = new FileReader();
                reader.onloadend = () => {
                    const imageRawData = reader.result as string;
                    const img = new Image();
                    setImage(imageRawData);
                    img.src = imageRawData;
                    img.onload = () => {
                        setImageLoaded(true);
                        setLoading(false);
                    };
                };
                reader.readAsDataURL(file);
            },300)

        }
    };

    const DragAndDropProps ={
        color:'play.600',
        description: 'Arrastar y soltar',
        buttonText: 'Cargar fichero',
        maxSize: "200 MB",
        typeAccept: "image",
        fileAccept: [".jpg", ".jpeg", ".png"],
        colorTextDrag:"#fff",
        colorTextFormats:"#fff",
    }

    const SpinnerProps = {
        color: "play",
    }

    const ButtonBackProps = {
        text: t('Nueva imagen'),
        type: 'subtle',
        color: 'white',
        bgColor: 'play.600',
        onClick: handleReset,
    }

    return (
        <Box
            maxWidth="778px"
            minHeight="100vh"
            w="778px"
            py="50px"
            px="10px"
            borderRadius="md"
            position="relative"
            color="white"
        >

            <Stack alignItems="flex-start" height="90%">
                <Flex alignItems="center" width={'100%'}>
                    <Flex mr="1.5rem">
                        <MainTitle text={t("Eyes")}/>
                    </Flex>
                    <Flex alignItems="center">
                        <Subtitle text={t("Image interaction")} icon={<AInteraction width={20} height={20} color="#FFF" />} />
                    </Flex>

                    {imageLoaded &&(
                        <Box marginLeft='auto'>
                            <Button {...ButtonBackProps}/>
                        </Box>
                    )}

                </Flex>
                <Text fontFamily={'Lato-Regular'} fontSize="1.1875rem" marginBottom="1.25rem">
                    {t("Upload an image and ask whatever you want to the AI.")}
                </Text>
                {!imageLoaded &&
                    <Box backgroundColor='#666' width='100%' border='1px solid #c1c1c1' padding='1.1875rem' borderRadius='1rem' marginTop='2.5rem'>
                        {!image &&
                            <>
                                {!image && !loading && (
                                    <DragAndDrop {...DragAndDropProps} onFilesDropped={handleFilesDropped} />
                                )}

                                {loading && (
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        height="200px"
                                        border="2px dashed #ccc"
                                        borderRadius="md"
                                        p="20px"
                                    >
                                        <Spinner {...SpinnerProps}/>
                                    </Box>
                                )}
                            </>
                        }
                    </Box>
                }
                {imageLoaded &&
                    <ImageInteractionChat image={image}/>
                }
            </Stack>
        </Box>
    );
}
